<template>
  <v-dialog v-model="dialog" width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="#f8b32b" style="color: white">
        <v-icon>mdi-plus</v-icon>
        Tambah Game List
      </v-btn>
    </template>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Tambah Game List</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form ref="formCreate" v-model="valid" lazy-validation>
        <v-card-text>
          <div class="d-flex flex-column" style="margin-top: 24px">
            <label class="form-title">Nama Game</label>
            <v-text-field
              hide-details
              label="Tuliskan Nama Game"
              name="gameName"
              single-line
              outlined
              v-model="gameName"
              :rules="fieldRequired"
            ></v-text-field>

            <label class="form-title">Logo Game</label>
            <v-file-input
              accept="image/*"
              name="gameLogo"
              :rules="fieldRequired"
              label="Pilih File Logo"
              v-model="gameLogo"
            ></v-file-input>

            <label class="form-title">Genre</label>
            <v-select
              v-model="selectedItems"
              label="Select One Or Many"
              :items="genreDatas"
              item-text="genre"
              chips
              clearable
              multiple
              return-object
              required
              :rules="select2"
            >
              <template v-slot:selection="data">
                <v-chip
                  :key="JSON.stringify(data.item)"
                  class="chip--select-multi"
                  @input="data.parent.selectItem(data.item)"
                >
                  {{ data.item.genre }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-tile-content>
                  <v-list-tile-title>
                    {{ data.item.genre }}
                  </v-list-tile-title>
                </v-list-tile-content>
              </template>
            </v-select>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="btn-posting"
            color="#f8b32b"
            style="
              color: white;
              padding: 14px;
              margin-bottom: 24px;
              margin-top: 24px;
            "
            @click="onStoreGameList"
            :loading="isLoading"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import * as imageConversion from "image-conversion";

export default {
  name: "CreateGameListComponent",
  components: {},
  props: {
    value: {
      type: [String, Object],
    },
  },
  data: () => ({
    valid: false,
    fieldRequired: [(v) => !!v || "This field is required"],
    select2: [(v) => v.length > 0 || "Item is required"],
    hidden: false,
    gameName: "",
    gameLogo: "",
    choosenGame: [],
    modal: false,
    dialog: false,
    isLoading: false,
    genreDatas: [],
  }),
  mounted() {
    this.fetchGenre();
  },
  computed: {
    selectedItems: {
      get() {
        return this.value;
      },
      set(item) {
        this.choosenGame = item;
        this.$emit("input", item);
      },
    },
  },
  methods: {
    fetchGenre() {
      const AuthStr = "Bearer ".concat(this.$store.getters.getToken);

      axios
        .create({ withCredentials: false })
        .get(`${process.env.VUE_APP_API_URL}/v1/admin/game_list/genre`, {
          headers: { Authorization: AuthStr },
        })
        .then((response) => {
          this.loading = false;
          this.genreDatas = response.data.result;
        })
        .catch((error) => {
          this.$toast.warning(error.response.data.message || "Unauthorized");
          this.loading = false;
        });
    },

    async onStoreGameList() {
      if (this.$refs.formCreate.validate()) {
        let config = {};
        if (this.$store.getters.getToken) {
          config = {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          };
        }

        const file = this.gameLogo;
        if (file) {
          const convert = await imageConversion.compressAccurately(file, 300);
          const new_file = new File([convert], file.name, {
            type: convert.type,
          });
          const form = new FormData();
          form.append("gameName", this.gameName);
          form.append("gameLogo", new_file);
          
          let genreIds = [];
          this.choosenGame.forEach(function (game) {
            genreIds.push(game.genreId);
          });

          form.append("genreIds", JSON.stringify(genreIds));

          this.isLoading = true;
          try {
            await axios
              .create({ withCredentials: false })
              .post(`${process.env.VUE_APP_API_URL}/v1/admin/game_list/store`, form, config);
            this.dialog = false;
            this.$toast.success("Berhasil tambah game");
            this.$emit("updateParent", "update");
            this.choosenGamea = [];
          } catch (error) {
            // this.$toast.warning(error.response.data["message"]);
            console.log(error);
          }
        }

        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.form-title {
  font-size: 16px;
  font-weight: 400;
  color: #141414;
  margin-top: 24px;
  margin-bottom: 8px;
}
.btn-posting {
  background-color: #f8b32b;
}
</style>

<template>
  <v-card>
    <v-card-title>
      Game List
      <!-- <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer /> -->
      <v-spacer />
      <div>
        <create-game-list-component @updateParent="fetch" />
        <v-select
          @change="fetch"
          v-model="sortBy"
          :items="sortByItems"
          dense
        ></v-select>
      </div>
    </v-card-title>
    <v-data-table
      :headers="sortBy == 'created tournament' ? headersCreated : headersJoined"
      :items="datas"
      :search="search"
      :items-per-page="50"
      mobile-breakpoint="0"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.gameTypeId }}</td>
          <td>
            <v-avatar>
              <img :src="IMAGE_URL + row.item.imageUrl" alt="John" />
            </v-avatar>
          </td>
          <td>{{ row.item.name }}</td>
          <td>{{ row.item.gameGenre }}</td>
          <td>{{ row.item.tournament_count }}</td>
          <td>
            <v-btn
              v-if="!row.item.deleted_at"
              small
              depressed
              color="red"
              @click="action(row.item.gameTypeId, 'delete')"
            >
              DELETE
            </v-btn>
            <v-btn
              v-else
              small
              depressed
              color="success"
              @click="action(row.item.gameTypeId, 'restore')"
            >
              RESTORE
            </v-btn>
          </td>
        </tr>
      </template></v-data-table
    >
  </v-card>
</template>

<script>
import axios from "axios";
import moment from "moment";
import CreateGameListComponent from "../components/CreateGameListComponent.vue";

export default {
  name: "GameList",

  components: { CreateGameListComponent },

  data() {
    return {
      search: "",
      IMAGE_URL: process.env.VUE_APP_IMAGE_URL,
      headersCreated: [
        { text: "ID", value: "gameTypeId" },
        { text: "Image", value: "imageUrl" },
        { text: "Game Name", value: "name" },
        { text: "Genre", value: "gameGenre" },
        { text: "Total Tournament", value: "tournament_count" },
        { text: "Atur", value: "" },
      ],
      headersJoined: [
        { text: "ID", value: "gameTypeId" },
        { text: "Image", value: "imageUrl" },
        { text: "Game Name", value: "name" },
        { text: "Genre", value: "gameGenre" },
        { text: "Total Registrant", value: "tournament_count" },
        { text: "Atur", value: "" },
      ],
      datas: [],
      sortBy: "created tournament",
      sortByItems: ["created tournament", "joined tournament"],
    };
  },

  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },

    fetch() {
      const AuthStr = "Bearer ".concat(this.$store.getters.getToken);
      let sortBy = this.sortBy.split(" ").join("_");

      axios
        .create({ withCredentials: false })
        .get(
          `${process.env.VUE_APP_API_URL}/v2/admin/game_list?sort_by=${sortBy}`,
          {
            headers: { Authorization: AuthStr },
          }
        )
        .then((response) => {
          this.datas = response.data.result;
          this.loading = false;
        })
        .catch((error) => {
          this.$toast.warning(error.response.data.message || "Unauthorized");
          this.loading = false;
        });
    },

    action(id, action) {
      let doConfirm = window.confirm(`Sure to ${action} this?`);

      if (doConfirm) {
        let config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        };

        if (action == "delete") {
          axios
            .create({ withCredentials: false })
            .delete(
              `${process.env.VUE_APP_API_URL}/v1/admin/game_list/${id}/${action}`,
              config
            )
            .then((response) => {
              this.fetch();
              this.$toast.success(response.data.message);
            })
            .catch((error) => {
              this.$toast.warning(
                error.response.data.message || "Unauthorized"
              );
              this.loading = false;
            });
        } else {
          axios
            .create({ withCredentials: false })
            .get(
              `${process.env.VUE_APP_API_URL}/v1/admin/game_list/${id}/${action}`,
              config
            )
            .then((response) => {
              this.fetch();
              this.$toast.success(response.data.message);
            })
            .catch((error) => {
              this.$toast.warning(
                error.response.data.message || "Unauthorized"
              );
              this.loading = false;
            });
        }
      }
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>
